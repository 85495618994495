'use strict';
$(document).ready(function() {

  var deviceAgent = navigator.userAgent.toLowerCase();
  var agentID = deviceAgent.match(/(iphone|ipod|ipad|android|webos|iemobile|opera mini|blackberry)/);

  if (agentID) {
    $('html').addClass('is-mobile');
  }

  if (navigator.userAgent.indexOf('Mac') != -1) {
    $('html').addClass('is-mac');
  }

  var breakpoints = {
    'minimum': 320, //*The smallest width that the site is able to shrink to */
    'tiny': 350,
    'small': 480,
    'mobile': 600,
    'phablet': 770, //*essentially the maximum for iPads in portrait*/
    'tablet': 960, //*!MAJOR BREAK POINT!*/ /*good place to switch to tablet view*/
    'large': 1024, //*maximum for iPads in landscape*/
    'page': 1200, //*!MAJOR BREAK POINT!*//*Point at which the edge of the desktop design meets the edge of the screen*/
    'wide': 1400,
    'big': 1600,
    'menu': 1280,
  };

  var toggleTransition = 300;


  $('.hamburger').click(function() {
    $('.header .main-menu').toggleClass('is-active');
  });






  
  $('img.svg').each(function() {
    var $img = $(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');
    var imgWidth = $img.attr('width');

    $.get(imgURL, function(data) {
      // Get the SVG tag, ignore the rest
      var $svg = $(data).find('svg');

      // Add replaced image's ID to the new SVG
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }

      if (typeof imgWidth !== 'undefined') {
        $svg = $svg.attr('width', imgWidth);
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
      }

      // Replace image with new SVG
      $img.replaceWith($svg);

    }, 'xml');
  });





   if ( $('.hero-slide .swiper-slide').length > 1 ) {

      var heroSlide = new Swiper('.hero-slide', {
         speed: 1000,
         parallax: true,
         pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function(index, className) {
               var index = (index + 1) < 10 ? '0' + (index + 1) : (index + 1);
               return '<span class="' + className + '"><span class="bullet-index">' + index + '</span></span>';
            },
         },
      });
   }

   var productSlide = new Swiper('.product-slide', {
      speed: 1000,
      parallax: true,
      navigation: {
         nextEl: '.product-slide__button-next',
         prevEl: '.product-slide__button-prev',
      },
      pagination: {
         el: '.product-slide__pagination',
      }
   });

   var productVariationSlide = new Swiper('.product-variation-slide', {
      speed: 1000,
      parallax: true,
      navigation: {
         nextEl: '.product-variation-slide__button-next',
         prevEl: '.product-variation-slide__button-prev',
      },
   });

   var productCarousel = new Swiper('.product-carousel', {
      speed: 1000,
      initialSlide: 1,
      parallax: true,
      loop: true,
      spaceBetween: 200,
      navigation: {
         nextEl: '.product-carousel__button-next',
         prevEl: '.product-carousel__button-prev',
      },
      pagination: {
         el: '.product-carousel__pagination',
      },
      breakpoints: {
         1680: {
            spaceBetween: 150
         },
      }
   });

   var partnerCarouselProps = {
      speed: 1000,
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 30,
      loop: true,
      // centeredSlides: true,
      pagination: {
         el: '.partner-carousel__pagination',
         clickable: true,
      },
      breakpoints: {
         768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
         },
         380: {
            slidesPerView: 1,
            slidesPerGroup: 1,
         },
      }
   };

   if ($(window).width() > 768) {
      if ($('.partner-carousel .swiper-slide').length > 3) {
         var partnerCarousel = new Swiper('.partner-carousel', partnerCarouselProps);
      }
   }else{
      var partnerCarousel = new Swiper('.partner-carousel', partnerCarouselProps);
   }

   var quoteCarousel = new Swiper('.quote-carousel', {
      speed: 1000,
      autoHeight: true,
      effect: 'fade',
      navigation: {
         nextEl: '.quote-carousel__button-next',
         prevEl: '.quote-carousel__button-prev',
      },
   });

   $('body').on('click', '.swiper-slide.swiper-slide-prev a, .swiper-slide.swiper-slide-next a', function(event) {
     event.preventDefault();
   });

   $(window).on('load resize', function(event) {
      event.preventDefault();

      $('.snippet-project__image img').each(function(index, el) {
        $(el).height( $(el).width() );
      });

      $('.snippet-news__image').each(function(index, el) {
        $(el).height( $(el).width()/1.5 );
      });




      

   });




  $('body').on('keyup click', '.form-control', function() {
    if ($(this).val().length > 0)
      $(this).addClass('is-active');
    else
      $(this).removeClass('is-active');
  });

  $('.form-control').each(function(index, el) {
    if ($(this).val().length > 0)
      $(this).addClass('is-active');
    else
      $(this).removeClass('is-active');
  });

  $('body').on('click', '#product-thumbanils a', function(event) {
    event.preventDefault();
    var urlImage = $(this).attr('href');
    $('#product-full-image').attr('src', urlImage);
  });

  $('body').on('click', '.form-group-qtd__controls > span', function() {
    var control = $(this).attr('data-control');
    var input = $(this).parents().eq(1).find('.form-group-qtd__input');
    var inputVal = Number(input.val());
    var inputMin = Number(input.attr('min'));
    var inputMax = Number(input.attr('max'));

    if (control == 'up') {
      if (inputVal < inputMax) {
        input.val(++inputVal);
      }

    }

    if (control == 'down') {
      if (inputVal > inputMin) {
        input.val(--inputVal);
      }
    }

  });

  var sliderHero = new Swiper('.slider-hero', {
    speed: 1000,
    parallax: true,
    pagination: {
      el: '.slider-hero__pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.slider-hero__button-next',
      prevEl: '.slider-hero__button-prev',
    },
  });
         

  $("#empresa_cep").on('blur', function(e) {
    var validacep = /^[0-9]{8}$/;
    var cep = $(this).val().replace(/\D/g, '');

    if (cep !== "" && validacep.test(cep)) {
      $.get('https://viacep.com.br/ws/' + cep + '/json/', function(data) {
        if (!("erro" in data)) {
          if (Object.prototype.toString.call(data) === '[object Array]') {
            var data = data[0];
          }

          $('#empresa_cep_endereco').val(data['logradouro']).click();
          $('#empresa_cep_cidade').val(data['localidade']).click();
          $('#empresa_cep_uf').val(data['uf']).click();
        }
      });
    }
  });

  // Formulário Newsletter
  $('.form-newsletter').validate({
    errorPlacement: function(error, element) {
      return false;
    },
    rules: {
      email: {
        email: true,
        required: true,
      },
    },
    submitHandler: function(form) {
      // Chamar ajax para envio de formulário
      $(form)[0].reset();
      $(form).find('.response-form').hide().html('<span>Obrigado! Email cadastrado com sucesso!</span>').fadeIn();
    }
  });
   
  $('body').on('click', '.jumper', function(e) {
    e.preventDefault();
    var anchor = $(this).attr('href');
    $('html,body').animate({ scrollTop: $(anchor).offset().top }, 1000);
  });


  // Formatando campos com mascaras
  var SPMaskBehavior = function(val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function(val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };

  $('.mask-phone').mask(SPMaskBehavior, spOptions);
  $('.mask-date').mask('99/99/9999');
  $('.mask-cep').mask('00000-000');
  $('.mask-cpf').mask('000.000.000-00');
  $('.mask-cnpj').mask('00.000.000/0000-00');


   // LightBox
   $(".open-fancybox").fancybox({
      // 'titleShow'     : false
   });

   SmoothScroll({
      animationTime: 1500,
      stepSize: 60,
      accelerationDelta: 1000,
   });

   // particles JS
   var configParticles = {
     "particles": {
       "number": {
         "value": 80,
         "density": {
           "enable": true,
           "value_area": 800
         }
       },
       "color": {
         "value": "#e3e3e3"
       },
       "shape": {
         "type": "circle",
         "stroke": {
           "width": 0,
           "color": "#000000"
         },
         "polygon": {
           "nb_sides": 5
         },
         "image": {
           "src": "img/github.svg",
           "width": 100,
           "height": 100
         }
       },
       "opacity": {
         "value": 1,
         "random": false,
         "anim": {
           "enable": false,
           "speed": 1,
           "opacity_min": 0.1,
           "sync": false
         }
       },
       "size": {
         "value": 3,
         "random": true,
         "anim": {
           "enable": false,
           "speed": 40,
           "size_min": 0.1,
           "sync": false
         }
       },
       "line_linked": {
         "enable": true,
         "distance": 189.39543399174545,
         "color": "#e3e3e3",
         "opacity": 0.4,
         "width": 2
       },
       "move": {
         "enable": true,
         "speed": 0.8,
         "direction": "none",
         "random": false,
         "straight": false,
         "out_mode": "out",
         "bounce": false,
         "attract": {
           "enable": false,
           "rotateX": 600,
           "rotateY": 1200
         }
       }
     },
     "interactivity": {
       "detect_on": "canvas",
       "events": {
         "onhover": {
           "enable": false,
           "mode": "repulse"
         },
         "onclick": {
           "enable": true,
           "mode": "push"
         },
         "resize": true
       },
       "modes": {
         "grab": {
           "distance": 400,
           "line_linked": {
             "opacity": 1
           }
         },
         "bubble": {
           "distance": 400,
           "size": 40,
           "duration": 2,
           "opacity": 8,
           "speed": 3
         },
         "repulse": {
           "distance": 200,
           "duration": 0.4
         },
         "push": {
           "particles_nb": 4
         },
         "remove": {
           "particles_nb": 2
         }
       }
     },
     "retina_detect": true
  };


  var rellax = new Rellax('.rellax');

  SmoothScroll({
    animationTime: 600,
    stepSize: 50,
    // accelerationDelta: 100,
  });

});



function scaleGallery()
{
  // This is roughly the max pixels width/height of a square photo
  var widthSetting = 400;
  
  // Do not edit any of this unless you know what you're doing
  var containerWidth = $(".gallery").width();
  var ratioSumMax = containerWidth / widthSetting;
  var imgs = $(".gallery img");
  var numPhotos = imgs.length, ratioSum, ratio, photo, row, rowPadding, i = 0;

  while (i < numPhotos) {
    ratioSum = rowPadding = 0;
    row = new Array();
    while (i < numPhotos && ratioSum < ratioSumMax) {
        photo = $(imgs[i]);
        // reset width to original
        photo.width(""); 
        ratio = photo.width() / photo.height();
        rowPadding += getHorizontalPadding(photo);
        // if this is going to be first in the row, clear: left
        if(ratioSum == 0) photo.css("clear", "left"); else photo.css("clear", "none");
        ratioSum += ratio;
        row.push(photo);
        i++;
        // if only 1 image left, squeeze it in
        if(i == numPhotos - 1) ratioSumMax = 999;
    }
    var unitWidth = (containerWidth - rowPadding) / ratioSum;

    row.forEach(function (elem) {
      elem.width(unitWidth * elem.width() / elem.height());
    });
  }
}

function getHorizontalPadding(elem)
{
    
    var padding = 0;
    var left = elem.css("padding-left");
    var right = elem.css("padding-right");
    padding += parseInt(left ? left.replace("px", "") : 0);
    padding += parseInt(right ? right.replace("px", "") : 0);
    return padding;
}

 





$(window).on('load', function() {




  $('body').addClass('on-load');

  $('.logo-hamburger-group').clone().appendTo('.col-logo-affix');

  let wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 100,
    mobile: true,
    live: true
  });

  wow.init();




var ua=navigator.userAgent,tem,M=ua.match(/(chrome|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 

if(M[1]==='Chrome'){
    $(".gallery").addClass('tres_colunas');
    $(".gallery img").css( { marginBottom : "10px" } );
} else {
    scaleGallery()

  $( window ).resize(function() {
    scaleGallery()
  });
      
}   


});


